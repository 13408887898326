/* You can add global styles to this file, and also import other style files */
html,
body {
	height: 100%;
	margin: 0;
	width: 100%;
}

body {
	background-image: url('./assets/imgs/tr-background-arrow_white.svg');
	background-position: 50%;
	background-position-x: -100px;
	background-repeat: no-repeat;
	background-size: cover;
	display: flex;
	flex-direction: column;
}

@media only screen and (max-height: 650px) {
	html,
	body {
		margin-bottom: 150px;
		margin-top: 50px;
	}
}

@media only screen and (max-height: 500px) {
	html,
	body {
		margin-bottom: 250px;
		margin-top: 120px;
	}
}

:root {
	--knowledgeFont: knowledge, sans-serif;
	--SourceSansPro: source sans pro, sans-serif;
}
